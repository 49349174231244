import * as React from "react";
import fetch from "isomorphic-fetch";
import Client, { LineItem } from "shopify-buy";

const CHECKOUT_CHECK_INTERVAL = 30 * 1000;

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN
  },
  fetch
);

interface StoreContextState {
  cart: Array<Object>;
  isOpen: boolean;
  loading: boolean;
  onOpen: () => void;
  onClose: () => void;
  addVariantToCart: (variantId: string, quantity: string) => void;
  removeLineItem: (checkoutID: string, lineItemID: string) => void;
  updateLineItem: (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ) => void;
  client: Object;
  checkout: {
    lineItems: Array<LineItem>;
  };
  didJustAddToCart: boolean;
  checkIsOrderComplete: () => void;
}

const defaultValues: StoreContextState = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  updateLineItem: () => {},
  client,
  checkout: {
    lineItems: []
  },
  didJustAddToCart: false,
  checkIsOrderComplete: () => {}
};

export const StoreContext =
  React.createContext<StoreContextState>(defaultValues);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout);
  const [loading, setLoading] = React.useState(false);
  const [didJustAddToCart, setDidJustAddToCart] =
    React.useState<boolean>(false);

  const setCheckoutItem = checkout => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id);
    }

    setCheckout(checkout);
  };

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          );
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null);
        }
      }

      const newCheckout = await client.checkout.create();

      setCheckoutItem(newCheckout);
    };

    initializeCheckout();
  }, []);

  const isOrderComplete = async (): Promise<boolean> => {
    const existingCheckoutID = isBrowser
      ? localStorage.getItem(localStorageKey)
      : null;
    const existingCheckout = await client.checkout.fetch(existingCheckoutID);

    if (existingCheckout.completedAt) {
      return true;
    }

    return false;
  };

  const checkIsOrderComplete = () => {
    setTimeout(async function check() {
      const isCompleted = await isOrderComplete();
      if (isCompleted) {
        return;
      }

      setTimeout(check, CHECKOUT_CHECK_INTERVAL);
    }, CHECKOUT_CHECK_INTERVAL);
  };

  const addVariantToCart = (variantId: string, quantity: string) => {
    setLoading(true);

    // @ts-ignore
    const checkoutID = checkout.id;

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10)
      }
    ];

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => setDidJustAddToCart(false), 3000);
      });
  };

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true);

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  const updateLineItem = (
    checkoutID: string,
    lineItemID: string,
    quantity: string
  ): void => {
    setLoading(true);

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) }
    ];

    // @ts-ignore
    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then(res => {
        setCheckout(res);
        setLoading(false);
      });
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
        checkIsOrderComplete
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
