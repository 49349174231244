exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-catalogue-js": () => import("./../../../src/pages/catalogue.js" /* webpackChunkName: "component---src-pages-catalogue-js" */),
  "component---src-pages-collections-all-js": () => import("./../../../src/pages/collections/all.js" /* webpackChunkName: "component---src-pages-collections-all-js" */),
  "component---src-pages-collections-city-diaries-js": () => import("./../../../src/pages/collections/city-diaries.js" /* webpackChunkName: "component---src-pages-collections-city-diaries-js" */),
  "component---src-pages-collections-editions-js": () => import("./../../../src/pages/collections/editions.js" /* webpackChunkName: "component---src-pages-collections-editions-js" */),
  "component---src-pages-collections-featured-js": () => import("./../../../src/pages/collections/featured.js" /* webpackChunkName: "component---src-pages-collections-featured-js" */),
  "component---src-pages-collections-final-copies-js": () => import("./../../../src/pages/collections/final-copies.js" /* webpackChunkName: "component---src-pages-collections-final-copies-js" */),
  "component---src-pages-collections-funding-js": () => import("./../../../src/pages/collections/funding.js" /* webpackChunkName: "component---src-pages-collections-funding-js" */),
  "component---src-pages-collections-recent-js": () => import("./../../../src/pages/collections/recent.js" /* webpackChunkName: "component---src-pages-collections-recent-js" */),
  "component---src-pages-collections-sale-js": () => import("./../../../src/pages/collections/sale.js" /* webpackChunkName: "component---src-pages-collections-sale-js" */),
  "component---src-pages-collections-sold-out-js": () => import("./../../../src/pages/collections/sold-out.js" /* webpackChunkName: "component---src-pages-collections-sold-out-js" */),
  "component---src-pages-collections-under-30-js": () => import("./../../../src/pages/collections/under-30.js" /* webpackChunkName: "component---src-pages-collections-under-30-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journal-js": () => import("./../../../src/pages/journal.js" /* webpackChunkName: "component---src-pages-journal-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-artist-template-js": () => import("./../../../src/templates/ArtistTemplate.js" /* webpackChunkName: "component---src-templates-artist-template-js" */),
  "component---src-templates-book-book-template-js": () => import("./../../../src/templates/Book/BookTemplate.js" /* webpackChunkName: "component---src-templates-book-book-template-js" */),
  "component---src-templates-tag-template-js": () => import("./../../../src/templates/TagTemplate.js" /* webpackChunkName: "component---src-templates-tag-template-js" */)
}

